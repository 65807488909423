<template>
  <div>
    <div
      class="p-2.5 border border-white rounded-lg cursor-pointer event-block overflow-hidden flex flex-col"
      :style="getStyle(event)"
    >
      <div v-if="!hideOverlap" class="mask mask--opacity">
        <RedMask />
      </div>
      <slot :event="event" :height="eventHeight" />
    </div>
    <div
      v-show="!hideActions && (actionsActive || actionsDropdown)"
      :style="getStyle(event, 'elipses')"
      @click="$emit('eventClicked')"
    >
      <UiOnClickOutside :do="outsideClicked">
        <div>
          <div
            class="absolute top-3 ltr:left-auto ltr:right-3 rtl:left-0 rtl:right-auto pl-3 pr-3 -mr-3"
            @click="actionsPopUp"
          >
            <icon
              class="cursor-pointer"
              height="14px"
              width="4px"
              icon="ellipsis"
              :color="eventActionIconcolor"
            />
            <div
              v-show="actionsDropdown"
              class="absolute event-top ltr:right-1.5 rtl:left-1.5 bg-white rounded border-1 border-primary-gray z-30"
            >
              <slot name="actions" :event="event" />
            </div>
          </div>
        </div>
      </UiOnClickOutside>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
import RedMask from '@assets/images/masks/red_mask.vue'
import icon from '@components/icons/icon.vue'
export default {
  name: 'Event',
  components: {
    UiOnClickOutside,
    RedMask,
    icon,
  },
  props: {
    event: {
      type: Object,
      default: () => {},
    },
    dayEvent: {
      type: Boolean,
      default: false,
    },
    tableTime: {
      type: [Object, Array],
      required: true,
    },
    tableHead: {
      type: [Object, Array],
      required: true,
    },
    hideOverlap: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    dynamicWidth: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['eventClicked'],
  data() {
    return {
      hexColorRegex: /^#([0-9A-F]{3}){1,2}$/i,
      actionsActive: true,
      actionsDropdown: false,
      eventHeight: 60,
    }
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      showRoutineDeleteModal: (state) => state.routine.showRoutineDeleteModal,
    }),
    eventActionIconcolor() {
      return this.hexColorRegex.test(this.event.subject.color) ? 'white' : `primary-gray-500`
    },
  },
  watch: {
    showModal: {
      handler(value) {
        if (value) this.actionsDropdown = false
      },
    },
    showRoutineDeleteModal: {
      handler(value) {
        if (value) this.actionsDropdown = false
      },
    },
  },
  methods: {
    outsideClicked(e) {
      if (this.actionsDropdown) {
        this.actionsPopUp()
      }
    },
    actionsPopUp(e, status = null) {
      if (e) e.stopPropagation()
      this.actionsDropdown = status === null ? !this.actionsDropdown : status
    },
    getStyle(eventObj, block = '') {
      const heightScale = 134
      const widthScale = this.dynamicWidth

      const tableStartMoment = moment(this.tableTime[0], 'hh:mm a')

      const diff = moment
        .utc(moment(eventObj.endTime, 'HH:mm:ss').diff(moment(eventObj.startTime, 'HH:mm:ss')))
        .format('HH:mm:ss')

      const [hour, minute] = diff.split(':')

      const timeDuration = {}

      timeDuration.hour = hour
      timeDuration.minute = minute

      let heightEvent = 0
      let widthEvent = widthScale

      if (timeDuration.hour > 0) {
        heightEvent = timeDuration.hour * heightScale
      }

      if (timeDuration.minute > 0) {
        heightEvent += (timeDuration.minute * heightScale) / 60
      }

      let positionTop = 0

      const startTime = moment(eventObj.startTime, 'HH:mm:ss')

      if (tableStartMoment.format('a') === startTime.format('a')) {
        if (+tableStartMoment.format('hh') === +startTime.format('hh')) {
          positionTop = 0
        } else {
          positionTop += +startTime.format('HH') - +tableStartMoment.format('HH')
        }
      } else {
        positionTop += +startTime.format('HH') - +tableStartMoment.format('HH')
      }

      let positionLeft = 90

      const headIdx = this.tableHead.indexOf(eventObj.day)

      positionTop = positionTop * heightScale

      if (startTime.format('mm') > 0) {
        positionTop += (startTime.format('mm') * heightScale) / 60
      }

      if (!this.dayEvent) {
        positionLeft = positionLeft + headIdx * widthScale
      }
      widthEvent = this.dayEvent ? `calc(100% - 100px)` : `${widthEvent - 4}px`
      /* SETTING MINIMUM HEIGHT */
      if (heightEvent < 70) {
        heightEvent = 70
      }
      this.eventHeight = heightEvent
      /* FINALLY OBJECT OF STYLE */
      let backgroundColor = this.hexColorRegex.test(eventObj.subject.color)
        ? eventObj.subject.color
        : `var(--${eventObj.subject.color}-50)`
      const styleObj = {
        backgroundColor,
        height: `${heightEvent - 2}px`,
        width: `${widthEvent}`,
        position: 'absolute',
        top: `${+positionTop + 1}px`,
      }
      if (block === 'elipses') delete styleObj.backgroundColor
      if (document.querySelector('body').dir === 'rtl') {
        styleObj['right'] = `${+positionLeft + 2}px`
      } else {
        styleObj['left'] = `${+positionLeft}px`
      }
      return styleObj
    },
  },
}
</script>

<style lang="scss" scoped>
.right-1-5 {
  right: 0.5em;
}
.event-block {
  margin-left: 1.4px;
}
.top-3 {
  top: 0.75em;
}

.right-3 {
  right: 0.75em;
}

.event-top {
  top: 1.3em;
}

.right-5 {
  right: 1.75em;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: -120px;
  opacity: 0.5;
}
</style>

<style lang="scss">
.mask--opacity {
  svg {
    path {
      opacity: 0.5;
    }
  }
}
</style>
