<template>
  <section class="overflow-auto bg-white border border-primary-gray-200 rounded-lg">
    <div v-if="!$slots.noEvent" class="schedular">
      <div class="schedular__head w-100 top-0 bg-primary-white">
        <ul class="flex items-center md:rtl:pr-1 bg-primary-white text-center">
          <li
            refs="timeHeading"
            class="sticky ltr:left-0 rtl:right-0 bg-primary-white font-inter font-medium text-text-color text-sm capitalize"
            :style="{ width: `${90}px` }"
          >
            <p
              v-i18n="title"
              class="py-4 border-r border-b border-primary-gray-200 font-inter text-xs leading-[18px] font-medium text-primary-gray-500"
            >
              Time
            </p>
          </li>
          <li
            v-for="(head, index) in tableHead"
            :key="head"
            class="font-inter font-medium text-text-color text-sm capitalize"
            :style="[theads.length > 1 ? { flex: 1 } : { width: '100%' }]"
          >
            <p
              v-i18n="title"
              :class="{ 'border-r border-primary-gray-200': index !== tableHead.length - 1 }"
              class="px-3 border-b py-4 font-inter text-xs leading-[18px] font-medium text-primary-gray-500"
            >
              {{ head }}
            </p>
          </li>
        </ul>
      </div>
      <div class="schedular__body relative">
        <ul
          v-for="time in tableTimeRange"
          :key="time"
          class="flex items-center sticky--leftbar bg-primary-white"
        >
          <li
            :style="elementStyle"
            class="flex justify-center border-b border-r border-primary-gray-200 font-normal uppercase font-inter text-xs leading-[18px] text-primary-gray-500"
          >
            <p class="pt-3">{{ time }}</p>
          </li>
          <li
            v-for="head in tableHead"
            :key="head"
            ref="infoBox"
            :style="headerStyle"
            class="flex items-center flex-1 border-b border-r last:border-r-0 border-primary-gray-200 scheduleBoxWidth"
          ></li>
        </ul>
        <ul
          v-if="isPeriodFromMultipleDays"
          class="flex items-center sticky--leftbar bg-primary-white"
        >
          <li
            :style="elementStyle"
            class="flex justify-center font-inter border-r last:border-r-0 border-b border-primary-gray-200 font-normal text-text-color text-sm uppercase"
          ></li>
          <li
            v-for="head in tableHead"
            :key="head"
            ref="infoBox"
            :style="headerStyle"
            class="flex items-center flex-1 border-b border-r last:border-r-0 border-primary-gray-200 scheduleBoxWidth"
          ></li>
        </ul>
        <Event
          v-for="(eventObj, idx) in events"
          :key="eventObj.id + idx"
          :table-time="tableTime"
          :table-head="tableHead"
          :event="eventObj"
          :hide-overlap="eventObj.isOverlapped === undefined ? hideOverlap : !eventObj.isOverlapped"
          :dynamic-width="dynamicWidth"
          :hide-actions="hideActions"
          @eventClicked="$emit('viewEvent', eventObj.id)"
        >
          <template v-slot="{ event, height }">
            <!-- EVENT SLOT CONTAINER -->
            <div class="flex flex-col h-full">
              <!-- EVENT-HEADER -->
              <div class="h-5">
                <!-- TITLE -->
                <p
                  class="capitalize truncate text-xs font-inter font-medium rtl:pl-2 ltr:pr-2"
                  :style="{
                    color: getEventColor(eventObj.subject.color, 600, 'white'),
                  }"
                >
                  {{ event.title }}
                </p>
              </div>
              <!-- EVENT-BODY -->
              <div class="overflow-y-hidden" :style="{ maxHeight: `${height - 28}px` }">
                <!-- TEACHER -->
                <div>
                  <p
                    v-if="!!event.teacher_id"
                    class="truncate text-10 text-primary-gray-600 font-inter font-normal"
                    :style="{
                      color: getEventColor(eventObj.subject.color, _, 'white'),
                    }"
                  >
                    <span v-i18n="dashboard">Teacher</span>
                    : {{ (event && event.teacher) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                  </p>
                  <!-- ROOM -->
                  <p
                    v-if="!!event.room"
                    class="truncate text-10 text-primary-gray-600 font-inter font-normal"
                    :style="{
                      color: getEventColor(eventObj.subject.color, _, 'white'),
                    }"
                  >
                    <span v-i18n="dashboard">Room</span>
                    : {{ (event && event.room) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                  </p>
                </div>
              </div>
              <div v-if="height > 105" class="mt-auto">
                <div
                  class="border-t-2 mb-[5px]"
                  :style="{
                    borderColor: getEventColor(eventObj.subject.color, 100, 'white'),
                  }"
                ></div>
                <div
                  v-if="!!event.startTime && !!event.endTime"
                  :style="{
                    backgroundColor: getEventColor(eventObj.subject.color, 100, 'white'),
                  }"
                  class="flex justify-center pt-0.5 px-0.5 rounded-full text-10 leading-[18px] text-primary-gray-500 font-inter font-normal"
                >
                  <p>
                    {{ localTime(event.startTime, true) || GENERAL_CONSTANTS.NOT_APPLICABLE }}-{{
                      localTime(event.endTime) || GENERAL_CONSTANTS.NOT_APPLICABLE
                    }}
                  </p>
                </div>
              </div>
              <!-- EVENT-FOOTER -->
              <div v-else class="text-center pt-0.5 mt-auto">
                <p
                  class="truncate text-10 text-primary-gray-600 font-inter font-medium cursor-pointer"
                >
                  <span v-i18n="dashboard">View More</span>
                </p>
              </div>
            </div>
          </template>
          <template v-slot:actions="{ event }">
            <ul v-if="!hideActions" class="py-1 actions-list">
              <li
                v-if="actions.includes('edit')"
                v-i18n="dashboard"
                class="actions-list__item w-24 cursor-pointer hover:bg-bg-color-light text-text-color text-xs px-2 py-1"
                @click="editEvent($event, event)"
              >
                Edit
              </li>
              <li
                v-i18n="attendanceTranslation"
                class="actions-list__item w-24 cursor-pointer hover:bg-bg-color-light text-text-color text-xs px-2 py-1"
                @click="deleteEvent($event, event)"
              >
                DS_DLT_TABLE_ACTION
              </li>
            </ul>
          </template>
        </Event>
      </div>
    </div>
    <slot name="noEvent"></slot>
  </section>
</template>

<script>
import { tableSide, getEventColor } from '@src/utils/timeTableUtils.js'
import Event from './Event.vue'
import timeMixin from '@src/mixins/timeMixin'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'

export default {
  name: 'Schedule',
  components: {
    Event,
  },
  mixins: [timeMixin],
  props: {
    theads: {
      type: Array,
      default: () => ['monday', 'friday'],
    },
    tableTime: {
      type: Array,
      default: () => ['10:00 am', '4:00 pm'],
    },
    events: {
      type: Array,
      default: () => [],
    },
    hideOverlap: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: () => ['edit', 'delete', 'deactivate'],
    },
  },

  emits: ['viewEvent', 'editEvent', 'deleteEvent'],
  data() {
    return {
      GENERAL_CONSTANTS,
      attendanceTranslation: 'attendanceTranslation',
      title: 'title',
      tHeadLable: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
      dashboard: 'dashboard',
      tableTimeRange: [],
      dynamicWidth: '',
      headerStyle: { height: `${134}px`, width: `${109}px` },
      elementStyle: { height: `${134}px`, width: `${90}px` },
    }
  },
  computed: {
    tableHead() {
      const startIdx = this.tHeadLable.indexOf(this.theads[0])
      const endIdx = this.tHeadLable.indexOf(this.theads[1])
      let tableHeadTemp = []
      if (this.theads.length > 1) {
        if (startIdx > endIdx) {
          tableHeadTemp = this.tHeadLable.slice(startIdx)
          tableHeadTemp = tableHeadTemp.concat(this.tHeadLable.slice(0, endIdx + 1))
        } else {
          tableHeadTemp = this.tHeadLable.slice(startIdx, endIdx + 1)
        }
      } else {
        tableHeadTemp = this.theads
      }
      return tableHeadTemp
    },
    isPeriodFromMultipleDays() {
      return this.tableTimeRange.includes('11:00 PM')
    },
  },
  watch: {
    tableTime: {
      immediate: true,
      handler() {
        this.setTableSide()
      },
    },
  },
  mounted() {
    // Code that will run only after the
    // entire view has been rendered
    // similar to jQuery.document.ready Function
    this.$nextTick(function () {
      // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
      new ResizeObserver(() => {
        this.getDynamicWidth()
      }).observe(this.$refs.infoBox[0])
      // Setting Variable Width
      this.getDynamicWidth()
    })
  },
  methods: {
    getEventColor,
    getDynamicWidth() {
      this.dynamicWidth = this.$refs.infoBox[0]?.clientWidth + 1
    },
    setTableSide() {
      this.tableTimeRange = tableSide(this.tableTime)
    },
    editEvent(e, event) {
      e.stopPropagation()
      this.$emit('editEvent', event)
    },
    deleteEvent(e, event) {
      e.stopPropagation()
      this.$emit('deleteEvent', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.schedular {
  min-width: 100% !important;
  .schedular__body {
    width: 100% !important;
  }
  .schedular__head {
    width: 100%;
  }
}

.schedular__body ul:last-of-type li:first-child {
  border-bottom: none;
}
.schedular__body ul:last-of-type li:last-child {
  border-bottom: none;
}

.schedular__body ul:last-of-type li:not(:first-child):not(:last-child) {
  border-bottom: none;
}
</style>
